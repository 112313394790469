import Vue from "vue";
import { DementiaState } from "@/types/dementia";

const getDefaultState = () => {
  return {
    screeningAnswers: Array(10),
    age: undefined,
    patientId: undefined,
    userStatus: undefined,
    gender: undefined,
    riskAnswers: new Array(16),
    score: undefined,
    isPostedGenomeApply: false,
    isPostedCheckResult: false,
    isRequestGenome: false,
    isRequestRemind: false
  } as DementiaState;
};
const state = getDefaultState();

export default {
  namespaced: true,
  state: state,
  mutations: {
    changeScreeningAnswerValue(
      state: DementiaState,
      payload: { id: number; score: number }
    ) {
      Vue.set(state.screeningAnswers, payload.id, payload.score);
    },
    changeRiskAnswerValue(
      state: DementiaState,
      payload: { id: number; value: string }
    ) {
      Vue.set(state.riskAnswers, payload.id, payload.value);
    },
    changeAgeAnswerValue(state: DementiaState, payload: { value: string }) {
      Vue.set(state, "age", payload.value);
    },
    changeGenderAnswerValue(state: DementiaState, payload: { value: string }) {
      Vue.set(state, "gender", payload.value);
    },
    changeTotalScoreValue(state: DementiaState, payload: { value: number }) {
      Vue.set(state, "score", payload.value);
    },
    changeIsPostedGenomeApply(
      state: DementiaState,
      payload: { value: boolean }
    ) {
      Vue.set(state, "isPostedGenomeApply", payload.value);
    },
    changeIsPostedCheckResult(
      state: DementiaState,
      payload: { value: boolean }
    ) {
      Vue.set(state, "isPostedCheckResult", payload.value);
    },
    changeIsRequestGenome(state: DementiaState, payload: { value: boolean }) {
      Vue.set(state, "isRequestGenome", payload.value);
    },
    changeIsRequestRemind(state: DementiaState, payload: { value: boolean }) {
      Vue.set(state, "isRequestRemind", payload.value);
    },
    changeUserInfo(
      state: DementiaState,
      payload: { patientId: number; userStatus: string }
    ) {
      Vue.set(state, "patientId", payload.patientId);
      Vue.set(state, "userStatus", payload.userStatus);
    },
    resetState(state: DementiaState) {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {},
  getters: {
    numberGender(state: DementiaState): number {
      return ["男性", "女性", "その他", "回答しない"].findIndex(
        el => el === state.gender
      );
    },
    numberAge(state: DementiaState): number {
      return [
        "50歳未満",
        "50~59歳",
        "60~64歳",
        "65~69歳",
        "70~74歳",
        "75~79歳",
        "80歳以上"
      ].findIndex(el => el === state.age);
    }
  }
};
