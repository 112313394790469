export default [
  {
    path: "/hae",
    name: "HaeHome",
    component: () => import("../views/hae/HaeHome.vue")
  },
  {
    path: "/hae/first_screening",
    name: "HaeFirstScreening",
    component: () => import("../views/hae/FirstScreening.vue")
  },
  {
    path: "/hae/first_result",
    name: "HaeFirstResult",
    component: () => import("../views/hae/FirstResult.vue")
  },
  {
    path: "/hae/second_screening",
    name: "HaeSecondScreening",
    component: () => import("../views/hae/SecondScreening.vue")
  },
  {
    path: "/hae/second_result",
    name: "HaeSecondResult",
    component: () => import("../views/hae/SecondResult.vue")
  },
  {
    path: "/hae/third_screening",
    name: "HaeThirdScreening",
    component: () => import("../views/hae/ThirdScreening.vue")
  },
  {
    path: "/hae/third_result",
    name: "HaeThirdResult",
    component: () => import("../views/hae/ThirdResult.vue")
  }
];
