export default [
  {
    path: "/adhd",
    name: "AdhdHome",
    component: () => import("../views/adhd/AdhdHome.vue")
  },
  {
    path: "/adhd/answer_result",
    name: "AdhdAnswerResult",
    component: () => import("../views/adhd/AnswerResult.vue")
  },
  {
    path: "/adhd/first_screening",
    name: "AdhdFirstScreening",
    component: () => import("../views/adhd/FirstScreening.vue")
  },
  {
    path: "/adhd/first_result",
    name: "AdhdFirstResult",
    component: () => import("../views/adhd/FirstResult.vue")
  },
  {
    path: "/adhd/second_screening",
    name: "AdhdSecondScreening",
    component: () => import("../views/adhd/SecondScreening.vue")
  },
  {
    path: "/adhd/second_result",
    name: "AdhdSecondResult",
    component: () => import("../views/adhd/SecondResult.vue")
  },
  {
    path: "/adhd/third_result",
    name: "AdhdThirdResult",
    component: () => import("../views/adhd/ThirdResult.vue")
  },
  {
    path: "/adhd/error",
    name: "AdhdError",
    component: () => import("../views/adhd/AdhdError.vue"),
    props: true
  }
];
