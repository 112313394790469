import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import VueGtag from "vue-gtag";
import dementiaRouter from "@/router/dementia";
import haeRouter from "@/router/hae";
import adhdRouter from "@/router/adhd";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  ...dementiaRouter,
  ...haeRouter,
  ...adhdRouter
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

router.afterEach(() => {
  if (!window.dataLayer) {
    return;
  }

  /*
    https://support.google.com/optimize/answer/7008840?hl=ja
    GoogleOptimize側でエクスペリエンスの「アクティベーションイベント」を
    評価： 「カスタムイベント時」
    イベント名： optimize.activate
    とすることで、ページ切り替え時にエクスペリエンスを適用できるようにする。
   */
  window.dataLayer.push({ event: "optimize.activate" });
});

const patientIdCookie = document.cookie
  .split(";")
  .map(v => {
    return v.split("=");
  })
  .find(v => {
    return v[0].includes("patient_id");
  });
const patientId = patientIdCookie ? patientIdCookie[1] : "";
const patientIdInt = Number.parseInt(patientId) || null;
Vue.use(
  VueGtag,
  {
    config: {
      id: process.env.VUE_APP_GA_ID,
      params: {
        patient_id: patientIdInt
      }
    },
    option: { globalObjectName: "gtag" },
    userId: patientId
  },
  router
);

export default router;
