import { AdhdQuestion, AskQA } from "@/types/adhd";
export function partAQuestions(): Array<AdhdQuestion> {
  return [
    {
      id: 1,
      text:
        "物事を行なうにあたって、難所は乗り越えたのに、詰めが甘くて仕上げるのが困難だったことが、どのくらいの頻度でありますか。",
      choices: ["非常に頻繁", "頻繁", "時々", "めったにない", "全くない"],
      answerPoints: [1, 1, 1, 0, 0]
    },
    {
      id: 2,
      text:
        "計画性を要する作業を行なう際に、作業を順序だてるのが困難だったことが、どのくらいの頻度でありますか。",
      choices: ["非常に頻繁", "頻繁", "時々", "めったにない", "全くない"],
      answerPoints: [1, 1, 1, 0, 0]
    },
    {
      id: 3,
      text:
        "約束や、しなければならない用事を忘れたことが、どのくらいの頻度でありますか。",
      choices: ["非常に頻繁", "頻繁", "時々", "めったにない", "全くない"],
      answerPoints: [1, 1, 1, 0, 0]
    },
    {
      id: 4,
      text:
        "じっくりと考える必要のある課題に取り掛かるのを避けたり、遅らせたりすることが、どのくらいの頻度でありますか。",
      choices: ["非常に頻繁", "頻繁", "時々", "めったにない", "全くない"],
      answerPoints: [1, 1, 0, 0, 0]
    },
    {
      id: 5,
      text:
        "長時間座っていなければならない時に、手足をそわそわと動かしたり、もぞもぞしたりすることが、どのくらいの頻度でありますか。",
      choices: ["非常に頻繁", "頻繁", "時々", "めったにない", "全くない"],
      answerPoints: [1, 1, 0, 0, 0]
    },
    {
      id: 6,
      text:
        "まるで何かに駆り立てられるかのように過度に活動的になったり、何かせずにいられなくなることが、どのくらいの頻度でありますか。",
      choices: ["非常に頻繁", "頻繁", "時々", "めったにない", "全くない"],
      answerPoints: [1, 1, 0, 0, 0]
    }
  ];
}

export function partBQuestions(): Array<AdhdQuestion> {
  return [
    {
      id: 1,
      text:
        "つまらない、あるいは難しい仕事をする際に、不注意な間違いをすることが、どのくらいの頻度でありますか。",
      choices: ["非常に頻繁", "頻繁", "時々", "めったにない", "全くない"],
      answerPoints: [0, 0, 0, 0, 0]
    },
    {
      id: 2,
      text:
        "つまらない、あるいは単調な作業をする際に、注意を集中し続けることが、困難なことが、どのくらいの頻度でありますか。",
      choices: ["非常に頻繁", "頻繁", "時々", "めったにない", "全くない"],
      answerPoints: [0, 0, 0, 0, 0]
    },
    {
      id: 3,
      text:
        "直接話しかけられているにもかかわらず、話に注意を払うことが困難なことはどのくらいの頻度でありますか。",
      choices: ["非常に頻繁", "頻繁", "時々", "めったにない", "全くない"],
      answerPoints: [0, 0, 0, 0, 0]
    },
    {
      id: 4,
      text:
        "家や職場に物を置き忘れたり、物をどこに置いたかわからなくなって探すのに苦労したことが、どのくらいの頻度でありますか。",
      choices: ["非常に頻繁", "頻繁", "時々", "めったにない", "全くない"],
      answerPoints: [0, 0, 0, 0, 0]
    },
    {
      id: 5,
      text:
        "外からの刺激や雑音で気が散ってしまうことが、どのくらいの頻度でありますか。",
      choices: ["非常に頻繁", "頻繁", "時々", "めったにない", "全くない"],
      answerPoints: [0, 0, 0, 0, 0]
    },
    {
      id: 6,
      text:
        "会議などの着席していなければならない状況で、席を離れてしまうことが、どのくらいの頻度でありますか。",
      choices: ["非常に頻繁", "頻繁", "時々", "めったにない", "全くない"],
      answerPoints: [0, 0, 0, 0, 0]
    },
    {
      id: 7,
      text:
        "落ち着かない、あるいはソワソワした感じが、どのくらいの頻度でありますか。",
      choices: ["非常に頻繁", "頻繁", "時々", "めったにない", "全くない"],
      answerPoints: [0, 0, 0, 0, 0]
    },
    {
      id: 8,
      text:
        "時間に余裕があっても、一息ついたり、ゆったりとくつろぐことが困難なことが、どのくらいの頻度でありますか。",
      choices: ["非常に頻繁", "頻繁", "時々", "めったにない", "全くない"],
      answerPoints: [0, 0, 0, 0, 0]
    },
    {
      id: 9,
      text:
        "社交的な場面でしゃべりすぎてしまうことが、どのくらいの頻度でありますか。",
      choices: ["非常に頻繁", "頻繁", "時々", "めったにない", "全くない"],
      answerPoints: [0, 0, 0, 0, 0]
    },
    {
      id: 10,
      text:
        "会話を交わしている相手が話し終える前に会話をさえぎってしまったことが、どのくらいの頻度でありますか。",
      choices: ["非常に頻繁", "頻繁", "時々", "めったにない", "全くない"],
      answerPoints: [0, 0, 0, 0, 0]
    },
    {
      id: 11,
      text:
        "順番待ちしなければならない場合に、順番を待つことが困難なことが、どのくらいの頻度でありますか。",
      choices: ["非常に頻繁", "頻繁", "時々", "めったにない", "全くない"],
      answerPoints: [0, 0, 0, 0, 0]
    },
    {
      id: 12,
      text:
        "忙しくしている人の邪魔をしてしまうことが、どのくらいの頻度でありますか。",
      choices: ["非常に頻繁", "頻繁", "時々", "めったにない", "全くない"],
      answerPoints: [0, 0, 0, 0, 0]
    }
  ];
}

export function askQA(): Array<AskQA> {
  return [
    {
      patientSex: "女性",
      patientAge: 30,
      qestionText: `最近、自分はADHDなのではないかと疑ってしまう時があります。仕事はそれなりに頑張っていますが、ほんのちょっとのミスが続きます。家でも片付けが苦手で、どこに何を置いたのか忘れてしまうなど、とにかく家事が苦痛で出来ません。毎日気持ちも不安定で浮き沈みが激しく、なんで自分はこうなんだと考えて辛くなります。改善したいのに出来ない。これはただの怠け者なんでしょうか。生きてるのが辛く感じます。`,
      doctorSex: "男性",
      doctorDepartment: "精神科医師",
      answerCount: 3,
      answerText: `おつらいですね。心よりお察し申し上げます。幼少期からそのような傾向がおありだったのでしたら、ADHDでいらっしゃる可能性があります。発達障害専門外来、行政の発達障害支援センター、精神科・心療内科のいずれかで御相談になられてみてはと存じ上げます。
最近とのことでしたら、疲労やストレス、睡眠不足、不規則な生活等の影響による注意力・集中力の低下かと思われます。その場合は、充分な心身の休養で御回復になられるかと思います。`
    },
    {
      patientSex: "女性",
      patientAge: 20,
      qestionText: `私はADHDでしょうか？仕事でケアレスミスが多く、同じことで何度もミス指摘をされます。小学生の頃はほぼ毎日忘れ物、中学、高校も小学生ほどじゃありませんが、忘れ物をしていました。集中力もなく、何か目に入ると気を取られてしまいます。こうしたい！あれしたい！と思うとすぐに行動しないと気が済みません。少しでも自分を変えたい、もしADHDなら受け入れて頑張っていきたいと思い、相談させていただきました。よろしくお願い致します。`,
      doctorSex: "男性",
      doctorDepartment: "精神科医師",
      answerCount: 3,
      answerText: `お話からは、幼少期から注意力・集中力の低下がおありのようですので、ADHDがおありである可能性はあります。確定診断には、御自身の主観的な訴えだけではなく、担任の先生や御家族様など身近な周囲の方の客観的な評価も必須とされており、さらに、学校と家庭等、2ヶ所以上の状況で御症状が生じていることも必要とされます。御家族様の御意見も御参考になさられた上で、発達障害専門外来か発達障害支援センター、精神科・心療内科で御相談になられてみてはと存じ上げます。`
    }
  ];
}
