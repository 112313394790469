import { AdhdState } from "@/types/adhd";
import { partAQuestions, partBQuestions } from "@/data/adhd";

const partALength = partAQuestions().length;
const partBLength = partBQuestions().length;
const getDefaultState = () => {
  return {
    screeningAnswersApart: Array.from(Array(partALength)),
    screeningAnswersBpart: Array.from(Array(partBLength)),
    patientId: undefined,
    userStatus: undefined,
    answerId: undefined,
    lastAnswerId: undefined
  };
};
const state: AdhdState = getDefaultState();

export default {
  namespaced: true,
  state: state,
  mutations: {
    changeScreeningAAnswerValue(
      state: AdhdState,
      payload: { id: number; value: number }
    ) {
      state.screeningAnswersApart.splice(payload.id - 1, 1, payload.value);
    },
    changeScreeningBAnswerValue(
      state: AdhdState,
      payload: { id: number; value: number }
    ) {
      state.screeningAnswersBpart.splice(payload.id - 1, 1, payload.value);
    },
    resetAllState(state: AdhdState) {
      Object.assign(state, getDefaultState());
    },
    resetBpartAnswer(state: AdhdState) {
      state.screeningAnswersBpart = Array.from(Array(partBLength));
    },
    resetAllAnswer(state: AdhdState) {
      state.screeningAnswersApart = Array.from(Array(partALength));
      state.screeningAnswersBpart = Array.from(Array(partBLength));
      state.answerId = undefined;
      state.lastAnswerId = undefined;
    },
    changeUserInfo(
      state: AdhdState,
      payload: { patientId: number; userStatus: string }
    ) {
      state.patientId = payload.patientId;
      state.userStatus = payload.userStatus;
    },
    changeAnswerId(state: AdhdState, payload: { answerId: number }) {
      state.answerId = payload.answerId;
    },
    changeLastAnswerId(state: AdhdState, payload: { lastAnswerId: number }) {
      state.lastAnswerId = payload.lastAnswerId;
    },
    resetAnswerId(state: AdhdState) {
      state.answerId = undefined;
    }
  },
  actions: {}
};
