<template>
  <div id="app" :class="namespace">
    <div id="header">
      <img
        alt="Askdoctors logo"
        src="./assets/dementia/logo_askdoctors.svg"
        class="logo-img"
      />
    </div>
    <div id="container" :class="namespace">
      <transition>
        <router-view />
      </transition>
    </div>
    <div id="footer" v-bind:class="{ margin: namespace !== 'adhd' }">
      <ul class="footer-ul">
        <li class="footer-li">
          <a href="https://www.askdoctors.jp/inquiry/new">お問い合わせ</a>
        </li>
        <li class="footer-li">
          <a href="https://forms.gle/51EcFEeXdeAm6Rfw6">法人のお問い合わせ</a>
        </li>
        <li class="footer-li">
          <a href="https://www.askdoctors.jp/doctors">協力医師一覧</a>
        </li>
        <li class="footer-li">
          <a href="https://www.askdoctors.jp/help">ヘルプ</a>
        </li>
        <li class="footer-li">
          <a href="https://www.askdoctors.jp/open/terms">利用規約</a>
        </li>
        <li class="footer-li">
          <a href="https://corporate.m3.com/privacy/">個人情報の取扱について</a>
        </li>
        <li class="footer-li">
          <a href="https://www.askdoctors.jp/open/trade"
            >特定商取引法に基づく表示</a
          >
        </li>
        <li class="footer-li">
          <a href="https://www.askdoctors.jp/open/about">運営会社</a>
        </li>
        <li class="footer-li">
          <a href="https://www.askdoctors.jp/sitemap">サイトマップ</a>
        </li>
      </ul>
      <hr />
      <br />
      <p class="bold-text">グループサイト</p>
      <ul class="footer-ul">
        <li class="footer-li">
          <a href="https://www.askdoctors.jp/articles">トピックス</a>
        </li>
        <li class="footer-li">
          <a href="https://askdoctorslab.jp/">AskDoctors総研</a>
        </li>
      </ul>
      <p class="copy-right">
        Copyright © 2005-{{ new Date().getFullYear() }} M3, Inc. All Rights
        Reserved.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    namespace() {
      const namespace = this.$route.path.split("/")[1];
      if (namespace === "hae") {
        return "hae";
      } else if (namespace === "adhd") {
        return "adhd";
      }
      return "dementia";
    }
  }
};
</script>

<style>
/* 共通部分 */
body {
  overflow-x: hidden;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 120%;
  color: #111111;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#container {
  max-width: 600px;
  margin: auto;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#header {
  background-color: white;
  height: auto;
  padding: 5px;
  margin-top: calc(-50vw + 50%);
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

#footer {
  border-top: 5px solid #28c8be;
  background: #ebf5f5;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

#footer .margin {
  margin-top: 40px;
}

#footer a {
  color: #324646;
  text-decoration: none;
}

[v-cloak] {
  display: none;
}

.footer-ul {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  max-width: 350px;
  margin-right: auto;
  margin-left: auto;
}

hr {
  border: 1px solid #dce1e1;
}

.footer-li {
  box-sizing: border-box;
  width: 50%;
  display: inline;
  text-align: left;
  padding: 0;
  margin: 0;
  font-size: 12px;
}

.copy-right {
  display: inline-block;
  color: #8c9ba0;
  font-size: 12px;
  font-size: 0.75rem;
  margin-top: 20px;
}

/* namespaceによる切り分け */
#app.dementia {
  background-color: #e5e5e5;

  color: #111111;
}

#app.hae {
  background-color: #007cc5;

  color: #004da1, 100%;
}

#container.hae {
  max-width: 370px;
}

#app.adhd {
  background-color: #e5e5e5;
  font-family: "Roboto", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS ゴシック", sans-serif;
}

#container.adhd {
  max-width: 416px;
}
img.logo-img {
  width: 40%;
  margin: 10px;
  max-width: 150px;
  vertical-align: middle;
}
</style>
