import Vue from "vue";
import { HaeState } from "@/types/hae";

const getDefaultState = () => {
  return {
    screeningAnswers: Array.from(Array(18)),
    patientId: undefined,
    userStatus: undefined
  };
};
const state: HaeState = getDefaultState();

export default {
  namespaced: true,
  state: state,
  getters: {
    screeningAnswers(state: HaeState): Array<number | number[] | undefined> {
      if (state.screeningAnswers === undefined) {
        return Array.from(Array(18));
      } else {
        return state.screeningAnswers.map(el => (el === null ? undefined : el));
      }
    }
  },
  mutations: {
    changeScreeningAnswerValue(
      state: HaeState,
      payload: { id: number; value: number }
    ) {
      // idは1から始まるが配列idxは0から使う
      Vue.set(state.screeningAnswers, payload.id - 1, payload.value);
    },
    resetState(state: HaeState) {
      Object.assign(state, getDefaultState());
    },
    changeUserInfo(
      state: HaeState,
      payload: { patientId: number; userStatus: string }
    ) {
      Vue.set(state, "patientId", payload.patientId);
      Vue.set(state, "userStatus", payload.userStatus);
    }
  },
  actions: {}
};
