import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import dementia from "./modules/dementia";
import hae from "./modules/hae";
import adhd from "./modules/adhd";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  modules: {
    dementia: dementia,
    hae: hae,
    adhd: adhd
  },
  plugins: [createPersistedState()]
});
