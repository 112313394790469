export default [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/dementia/Home.vue")
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/dementia/Profile.vue")
  },
  {
    path: "/risk",
    name: "Risk",
    component: () => import("../views/dementia/Risk.vue")
  },
  {
    path: "/screening",
    name: "Screening",
    component: () => import("../views/dementia/Screening.vue")
  },
  {
    path: "/remind",
    name: "Remind",
    component: () => import("../views/dementia/Remind.vue")
  },
  {
    path: "/result",
    name: "Result",
    component: () => import("../views/dementia/Result.vue")
  }
];
